import React from "react";
import styled from "styled-components";
import Text from "../common/Text";
import Space from "../common/Space";
import Img from "gatsby-image";
import { graphql, Link, useStaticQuery } from "gatsby";
import { Event, track } from "../../analytics";

export const query = graphql`
  query {
    file(relativePath: { eq: "profile_picture.png" }) {
      childImageSharp {
        fluid(
          maxWidth: 30
          maxHeight: 30
          fit: COVER
          quality: 100
          cropFocus: NORTH
          duotone: { highlight: "#22fcd0", shadow: "#212231" }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const ImageContainer = styled.div`
  width: 20px;
`;

const Image = styled(Img)`
  border-radius: 50%;
`;

const Container = styled(Link)`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background-color: transparent;
  border: 0px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background-color: ${(props) => props.theme.colors.accent.primary[50]};
  }
  &:focus {
    background-color: ${(props) => props.theme.colors.accent.primary[50]};
    outline: none;
  }
`;

const MiniAvatar = ({ from, title }) => {
  const data = useStaticQuery(query);
  return (
    <Container
      to="/"
      onClick={() =>
        track(Event.avatar_navigation_clicked, { from: from, title: title })
      }
    >
      <ImageContainer>
        <Image
          fluid={data.file.childImageSharp.fluid}
          alt="profile picture"
          fadeIn
        />
      </ImageContainer>
      <Space margin="0 0 0 8px">
        <Text size="xsmall" weight="light">
          {"Devanshi Goradia"}
        </Text>
      </Space>
    </Container>
  );
};

export default MiniAvatar;
